import { configureStore } from '@reduxjs/toolkit';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';
import AuthReducer from './apps/auth/AuthSlice';


export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notes: NotesReducer,
    chat: ChatsReducer,
    contacts: ContactsReducer,
    email: EmailReducer,
    ticket: TicketReducer,
    auth: AuthReducer,
  },
});


export default store;
