import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios/axiosInstance';



const authTokenSa = localStorage.getItem('X-Red-Store-Token');
const initialState = {
  isAuthenticated: !!authTokenSa,
  user: null,
  loading: false,
  error: null,
  authToken: authTokenSa || null,
  image: null,
  users: [],
  role: null,
  bankDetails: [],
};

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
  const response = await axiosInstance.get('/me');
  return response.data;
});




export const addUser = createAsyncThunk('auth/addUser', async (newUser) => {
  const response = await axiosInstance.post('/addUser', newUser);
  return response.data.user;
});


export const fetchUsers = createAsyncThunk('auth/fetchUsers', async () => {
  const response = await axiosInstance.get('/getAllusers');
  return response.data.user;
});

export const updateUser = createAsyncThunk('auth/updateUser', async ({ id, updatedUser }) => {
  const response = await axiosInstance.post(`/updateUser/${id}`, updatedUser);
  return response.data.user;
});

export const recoverPassword = createAsyncThunk('auth/recoverPassword', async (email, { dispatch }) => {
    const response = await axiosInstance.post('/recoverPassword', { email });
    return response.data;
});

export const deleteUser = createAsyncThunk('auth/deleteUser', async (userId, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`/deleteUser/${userId}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.role = action.payload.user.role;
      state.loading = false;
      state.error = null;
      state.image = action.payload.user.image;
      state.authToken = action.payload.token;
      localStorage.setItem('X-Red-Store-Token', action.payload.token);
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.role = null;
      state.error = null;
      state.authToken = null;
      localStorage.removeItem('X-Red-Store-Token');
    },

    recoverPasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    recoverPasswordSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      // you can also update the state with the response data
      // state.recoveryToken = action.payload.recoveryToken;
    },
    recoverPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.user = action.payload;
        state.role = action.payload.role;
        state.loading = false;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch user data';
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isFetchingUsers = true; // New state for user fetching
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isFetchingUsers = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isFetchingUsers = false;
        state.error = action.error.message || 'Failed to fetch users';
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add user';
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.users.findIndex((user) => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update user';
      })
      .addCase(recoverPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(recoverPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(recoverPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to recover password';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(user => user.id !== action.meta.arg);
      })
      .addCase(deleteUser.rejected, (state, action) => {
          state.error = action.error.message || 'Failed to delete user';
      });
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout  } = authSlice.actions;

export const login = createAsyncThunk('auth/login', async (credentials, { dispatch }) => {
  dispatch(loginRequest());
  try {
    const response = await axiosInstance.post('/login', credentials);
    if (response && response.data && response.data.token) {
      dispatch(loginSuccess(response.data));
      //dispatch(fetchUserData()); 
    } else {
      dispatch(loginFailure('Empty response or invalid data structure'));
    }
  } catch (error) {
    dispatch(loginFailure('Login failed please try again'));
  }
});

export default authSlice.reducer;
